<template>
  <div class="register">
    <headerother titleurl="homepage" ref="header"></headerother>
    <div class="register-bgc">
      <div class="header">
        <div class="header-left">
          <span class="phone">找回密码</span>
        </div>
        <div class="header-right">
          <span>已有账号?</span>
          <span class="signin">
            <router-link to="/">去登录</router-link>
          </span>
        </div>
      </div>
      <!-- 内容区域布局开始 -->
      <el-form :rules="rules" :model="ruleForm" ref="ruleForm" label-width="0" class="demo-ruleForm">
        <div class="container">
          <div class="container-zh">
            <div class="container-zh-left">手机号</div>
            <div class="container-zh-right">
              <el-form-item prop="user">
                <el-input style="width:300px" v-model="ruleForm.user" class="input-number" type="number"
                  placeholder="请输入手机号码" @input="changeInput()" />
              </el-form-item>
            </div>
          </div>
          <div class="message-number container-zh">
            <div class="container-zh-left">验证码</div>
            <div class="container-zh-right">
              <el-form-item prop="code">
                <el-input style="width:300px" class="input-number" type="number" placeholder="请输入验证码"
                  v-model="ruleForm.code" />
                <span @click="getCode('ruleForm')">{{codeMsg}}</span>
              </el-form-item>
            </div>
          </div>
          <div class="message-number container-zh">
            <div class="container-zh-left">密码</div>
            <div class="container-zh-right">
              <el-form-item prop="pw">
                <el-input style="width:300px" class="input-number" type="password" placeholder="请输入密码"
                  v-model="ruleForm.pw" />
              </el-form-item>
            </div>
          </div>
          <div class="reg-btn">
            <el-button @click="submit('ruleForm')">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {
  usergetcurruserinfo,
  usersenduserfindpwdsms,
  userfindpassword,
} from '@/api/user';
import headerother from '../../components/headerother';
import Footer from '../../components/footer';
import Cookies from 'js-cookie';
export default {
  name: 'homepage',
  components: {
    headerother,
    Footer,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          return callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      num: '1',
      dbget: false,
      // 只可请求一次
      isget: false,
      // 确认密码
      rules: {
        user: [{ validator: checkPhone, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        pw: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      },
      ruleForm: {
        user: '',
        code: '',
        pw: '',
      },
      codeMsg: '获取验证码',
      second: 60,
      timer: null,
    };
  },
  methods: {
    changeInput() {
      var phone = /^[1]([3-9])[0-9]{9}$/;
      this.dbget = false;
      if (phone.test(this.ruleForm.user)) {
        this.dbget = true;
        this.num = 1;
      }
    },

    getCode(formName) {
      this.dbget = true;
      if (this.ruleForm.user == '') {
        this.$message.error('请输入手机号');
        return;
      }
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      usersenduserfindpwdsms({ userMobile: this.ruleForm.user }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: '发送成功',
            type: 'success',
          });
          this.codeMsg = `${this.second}s后重试`;
          this.timer = setInterval(() => {
            this.second--;
            this.codeMsg = `${this.second}s后重试`;
            if (this.second == 0) {
              clearInterval(this.timer);
              this.second = 60;
              this.codeMsg = '获取验证码';
            }
          }, 1000);
        } else {
          this.$message.error(res.data.message);
          //this.phone = false;
        }
      });
      return;
    },

    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var form = {
            userMobile: this.ruleForm.user,
            verifCode: this.ruleForm.code,
            newPassword: this.ruleForm.pw,
          };
          var that = this;
          userfindpassword(form).then((res) => {
            if (res.data.code === 1) {
              this.$message.success('提交成功');
              setTimeout(function () {
                window.location.href="/index";
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
          return;
        } else {
          return false;
        }
      });
    },
  },
  created() {},
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.register .register-bgc {
  /* width: 100%; */
  margin: 0 auto;
  background: #f2f2f2;
  padding-top: 40px;
}
.register .register-bgc .header {
  width: 55%;
  height: 38px;
  margin: 0 auto;
  /* border: 1px solid red; */
  overflow: hidden;
}
.header .header-left {
  float: left;
}
.header .header-left span {
  width: 150px;
  height: 38px;
  border: none;
  display: block;
}
.header .header-left .phone {
  color: #ffffff;
  background: linear-gradient(196deg, #5aa5ff, #0d7bff);
  border-radius: 5px 5px 0 0;
  font-size: 17px;
  line-height: 38px;
  text-align: center;
  font-weight: 600;
}
.header .header-left .acnumber {
  margin-left: 12px;
}

.header .header-right {
  float: right;
  line-height: 38px;
}
.header .header-right a {
  color: #3194fd;
}
.header .header-right span {
  font-size: 13px;
  display: inline-block;
}

.header .header-right .signin {
  width: 44px;
  padding: 0;
  /* border: 1px solid red; */
  color: #0d81fe;
  cursor: pointer;
}
.container {
  width: 55%;
  height: 386px;
  background-color: #ffffff;
  padding-top: 70px;
  margin: 0 auto;
}

/* 内容区域css开始 */
.container-zh {
  width: 420px;
  height: 60px;
  /* background: #f7f7f7; */
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.container-zh-left {
  text-align: justify;
  text-align-last: justify;
  width: 90px;
  height: 100%;
  padding: 0 16px;
  line-height: 42px;
  float: left;
  font-family: '微软雅黑';
  color: #626262;
  display: none;
}
.lb_opacity_Class {
  display: block;
  position: absolute;
  cursor: text;
  float: left;
  z-index: 2;
  padding-left: 0px;
  padding-top: 6px !important;
}
.input-number {
  height: 42px;
  border: none;
  /* float: left; */
}
.message-number {
  margin-top: 3px;
}
.container-zh-right {
  float: left;
  position: relative;
}
.container-zh-right span {
  float: right;
  font-size: 14px;
  line-height: 31px;
  margin-right: 10px;
  color: #3194fd;
  cursor: pointer;
  top: 0;
  margin-left: 5px;
  border: 1px solid #ddd;
  padding: 3px 10px;
  border-radius: 5px;
  color: #8a8a8a;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.reg-btn {
  margin-top: 30px;
  text-align: center;
  margin-right: 80px;
}
.reg-btn .btn.sel {
  background-color: #228bff;
  border: 0;
  color: #fff;
}
.btn {
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  background: #fff;
  cursor: pointer;
}
input {
  outline: 0;
}
.w-55 {
  /* width: 55%; */
  border: 0.5px solid #dedede;
  margin-top: 54px;
}
.footer-zc {
  width: 55%;
  /* border: 1px solid red; */
  margin-top: 44px;
  margin: 0 auto;
  text-align: center;
}
.footer-agreement {
  line-height: 24px;
  font-size: 12px;
  color: #999;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.el-input__suffix {
  z-index: 100 !important;
}

.register-bgc .reg-btn .el-button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
  width: 120px;
}
</style>